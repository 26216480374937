import React, { Component } from 'react';
import get from 'lodash/get'
import "../scss/components/mapbox.scss"


class MapCompany extends Component {
  render() {
    // function to format phone numbers
    let formatPhoneNumber = (str) => {

      // filter so only numbers are left
      let cleaned = ('' + str).replace(/\D/g, '');
      
      // check if the input is the correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      // if all checks out, add parentheses and dashes
      if (match) {
        return '(' + match[1] + ')-' + match[2] + '-' + match[3]
      };
      return null
    };

    // fetches content passed in via the component declaration
    const companies = get(this, 'props.companies');

    // outputs a grey block for each set of company info
    const company = companies.map((company, index) => {
      return (
        <div key={company.node.id} id={'map-'+(company.node.id)} className={'map-single map-' + company.node.simpasOrTrimble.toLowerCase() + (index < 1 ? ' map-selected': '')} data-target={'marker-'+(company.node.id)} data-lat={company.node.locationCoordinates.lat} data-lng={company.node.locationCoordinates.lon} data-order={index}>
          <p><strong>{company.node.simpasOrTrimble === "SIMPAS" ? 'SIMPAS-applied Solutions Retail Agent' : 'Trimble Equipment'}</strong></p>
          <p>{company.node.businessName}<br/>
          {company.node.address}<br/>
          {company.node.city}, {company.node.state} {company.node.zipCode}</p>
          <p>{/*{company.node.mainContactName}<br/>*/}
          <a href={'tel:+1' + (''+company.node.contactPhoneNumber).replace(/\D/g, '')}>{formatPhoneNumber(company.node.contactPhoneNumber)}</a>
          {/*<a href="mailto:{company.node.emailAddress}">{company.node.emailAddress}</a>*/}</p>
          <a href={company.node.websiteUrl} target="_blank" rel="noopener noreferrer" className="btn btn--blue">Visit Website</a>
          <a href={"https://www.google.com/maps/dir//"+(company.node.businessName+",+"+company.node.address+"+"+company.node.city+", "+company.node.state+"+"+company.node.zipCode).replace(" ", "+")} target="_blank" rel="noopener noreferrer" className="btn btn--gray">Get Directions</a>
        </div>
      )
    });

    return (
      <>{company}</>
    )
  }
};

export default MapCompany;