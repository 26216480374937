import React, { Component } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import MapCompany from "../components/mapCompany"
import mapboxgl, { NavigationControl } from "!mapbox-gl"
import distance from "@turf/distance"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/locations.scss"
import "../../node_modules/mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

// api token for mapbox account
mapboxgl.accessToken =
  "pk.eyJ1IjoiZmhpZ3VlcmEtYW0iLCJhIjoiY2tqdWl0d25kODZkYzJzbnBydHI4dnBwYSJ9.Fs--PT8rkj89QMI13P13Mg"

// define a few global variables for hoisting purposes
let gMapbox = {}
let gCompanies = {}
let windowWidth= 0
let tempSimpas = 0
let tempMarker, tempSimpasDist
let options = { units: "miles" }
let reps = [
            {
              id: "CR",
              states: ["Connecticut", "Delaware", "Illinois", "Indiana", "Kentucky", "Maine", "Maryland", "Massachusetts", "Michigan", "Missourie", "New Hampshire", "New Jersey", "New York", "Ohio", "Pennsylvania", "Rhode Island", "Vermont", "West Virginia"],
            },{
              id: "KO",
              states: ["Alaska", "Arizona", "California", "Colorado", "Hawaii", "Idaho", "Kansas", "Montana", "Nebraska", "Nevada", "New Mexico", "North Dakota", "Oklahoma", "Oregon", "South Dakota", "Utah", "Washington", "Wyoming"],
            },{
              id: "DE",
              states: ["Alabama", "Arkansas", "Florida", "Georgia", "Louisiana", "Mississippi", "North Carolina", "South Carolina", "Tennessee", "Texas", "Virginia"],
            },{
              id: "BM",
              states: ["Iowa", "Minnesota", "Wisconsin"],
            }]

// small function to check window width and prepare to add some space to the mapbox based on the map-bar width
function offset() {
  windowCheck()
  return windowWidth > 768 &&
    document.querySelector(".map-bar").classList.contains("out")
    ? document.getElementsByClassName("map-bar")[0].offsetWidth / 2
    : 0
}

// simple window resize to change current window width variable
function windowCheck() {
  window.onresize = function () {
    windowWidth = window.innerWidth
  }
  windowWidth = window.innerWidth
}

// function for the map sidebar toggle button
function mapOut() {
  document.getElementsByClassName("map-bar")[0].classList.toggle("out")
}

// if the rep popup is visible, hide it before next step
function hideReps() {
  if (document.getElementsByClassName('simpas-reps-show').length > 0) {
    document.getElementsByClassName('simpas-reps-show')[0].classList.toggle('simpas-reps-hide')
    document.getElementsByClassName('simpas-reps-show')[0].classList.toggle('simpas-reps-show')
  }
}

class LocationsPage extends Component {
  componentDidMount() {
    // Scroll to map section
    if (document.location.hash === '#map-container') {
      window.scrollTo({
        top: document.getElementById("map-container").offsetTop,
        behavior: "smooth"
      })
    }
    // initialize the mapbox
    const mapbox = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/fhiguera-am/ckpek8uit0c0717p6imi24qbq",
      center: [-89.6974286638079, 35.05494545477954],
      showZoom: true
    })
    mapbox.flyTo({
      center: { lng: -89.6974286638079, lat: 35.05494545477954 },
      offset: [offset(), 0],
      zoom: 7,
    })
    mapbox.addControl(new mapboxgl.NavigationControl())

    // grab all the locations generated by the mapCompany component
    const mapSingles = document.getElementsByClassName("map-single")

    // start checking for the locations in the sidebar to make sure they've been generated properly
    const singlesCheck = setInterval(function () {
      // once confirmed, iterate through them and add a listener for clicks
      if (mapSingles.length > 0 && mapSingles !== null) {
        for (var i = mapSingles.length - 1; i >= 0; i--) {
          mapSingles[i].addEventListener("click", function (e) {
            markerSwap(this.id)
          })
        }
        // turn off the interval check when this goes through
        clearInterval(singlesCheck)
      }
      // interval set in milliseconds
    }, 100)

    // function to swap out active markers and sidebar elements with new selection
    function markerSwap(el, zoom = true) {
      // get the marker information that matches el
      let elm = document.getElementById(el.replace("marker-", "map-"))

      // center mapbox on the targeted element if zoom isn't set to false
      if (zoom === true) {
        gMapbox.flyTo({
          center: { lng: elm.dataset.lng, lat: elm.dataset.lat },
          offset: [offset(), 0],
          zoom: 10,
        })
      }

      // swap out selected classes on markers and sidebar for new target
      document
        .getElementsByClassName("marker-selected")[0]
        .classList.toggle("marker-selected")
      document
        .getElementsByClassName("map-selected")[0]
        .classList.toggle("map-selected")
      document
        .getElementById(elm.dataset.target)
        .classList.toggle("marker-selected")
      elm.classList.toggle("map-selected")

      // make sure to focus on the proper element in the sidebar
      // if (windowWidth < 768) {
      //   window.scrollTo({
      //     top: document.getElementById("map-container").offsetTop,
      //     behavior: "smooth",
      //   })
      // } else {
      //   document
      //     .getElementById("map-locations")
      //     .scrollTo({ top: 0, behavior: "smooth" })
      // }

      // reorder the elements in the sidebar based on which is active
      document.querySelector('[data-order="0"]').dataset.order =
        elm.dataset.order
      elm.dataset.order = 0
      let locationsHolder = document.getElementById("map-locations")
      let holdLocations = locationsHolder.childNodes
      let tempLocations = []
      tempLocations[0] = holdLocations[0]
      for (var i = 0; i < holdLocations.length; i++) {
        tempLocations[parseInt(holdLocations[i].dataset.order) + 1] = {
          node: holdLocations[i].outerHTML,
          id: holdLocations[i].id,
        }
      }
      while (locationsHolder.childNodes.length > 1) {
        locationsHolder.removeChild(locationsHolder.childNodes[1])
      }
      for (var k = 1; k < tempLocations.length; k++) {
        locationsHolder.innerHTML += tempLocations[k].node
      }
      holdLocations = locationsHolder.childNodes

      // reapply the sidebar location click throughs after reordering it
      for (var j = 0; j < holdLocations.length; j++) {
        holdLocations[j].addEventListener("click", function (e) {
          if(!this.classList.contains("map-selected")) {
            e.stopPropagation()
            e.preventDefault()
            markerSwap(this.id)
          }
        })
      }

      // expand sidebar if minimized
      if(!document.getElementsByClassName("map-bar")[0].classList.contains("out")) {
        mapOut()
      }

      // run back to top of the locations holder div
      locationsHolder.scrollTop = 0
    }

    // make sure everything gets a chance to load before referencing anything
    mapbox.on("load", function () {
      windowCheck()

      // create an array formatted for the geojson function based on the data passed from contentful
      const companyList = gCompanies.map((company, index) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            company.node.locationCoordinates.lon,
            company.node.locationCoordinates.lat,
          ],
        },
        properties: {
          id: "marker-" + company.node.id,
          target: "map-" + company.node.id,
          title: company.node.businessName,
          className:
            company.node.simpasOrTrimble.toLowerCase() +
            (index < 1 ? " marker-selected" : ""),
          description:
            (company.node.simpasOrTrimble === "SIMPAS"
              ? "SIMPAS-applied Solutions Retail Agent"
              : "Trimble Equipment") +
            " " +
            company.node.businessName +
            " " +
            company.node.address +
            " " +
            company.node.city +
            ", " +
            company.node.state +
            " " +
            company.node.zipCode,
        },
      }))

      // set the full geojson variable
      const geojson = {
        type: "FeatureCollection",
        features: companyList,
      }

      // add them to the map based on the geojson object
      geojson.features.forEach(function (marker) {
        // create an HTML element for each feature
        const el = document.createElement("div")

        // check if this is a simpas or trimble location and add respective class
        el.className = "marker-" + marker.properties.className

        // add id's for controlling functions later
        el.setAttribute("id", marker.properties.id)

        // initialize click function for markers
        el.addEventListener("click", e => {
          e.stopPropagation()
          e.preventDefault()
          markerSwap(el.id)
        })

        // add each marker element to the map
        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(mapbox)
      })

      // hoist away that mapbox for a later function
      gMapbox = mapbox

      // Add the geocoder controller to the map
      var geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: true,
        autocomplete: true,
        countries: "us",
        minLength: 3,
        placeholder: "Enter ZIP code or city+state",
        inputClass: "map-input",
        resultsClass: "map-output",
        bbox: [-171.791110603, 18.91619, -66.96466, 71.3577635769],
        proximity: { longitude: -90.0242, latitude: 35.129166 },
        types: "postcode,place,region",
        // since this is all limited to the USA right now, strip out country name from results
        render: function (item) {
          return item.place_name.replace(", United States", "")
        },
      })

      // add the search bar to the proper location
      geocoder.addTo("#geocoder")

      // when a location is chosen, do a bunch of stuff
      geocoder.on("result", function (e) {
        // add a temporary marker based on searched location and remove any that currently exist
        if (tempMarker !== undefined) tempMarker.remove()
        tempMarker = new mapboxgl.Marker()
          .setLngLat({
            lon: e.result.geometry.coordinates[0],
            lat: e.result.geometry.coordinates[1],
          })
          .addTo(mapbox)

        // since this is all limited to the USA right now, strip out country name from results
        document.getElementById("results").innerHTML =
          'Results for "' +
          e.result.place_name.replace(", United States", "") +
          '"'

        // run through the locations and determinne which is closer and which is farther
        geojson.features.forEach(function (company) {
          Object.defineProperty(company.properties, "distance", {
            value: distance(e.result.geometry, company.geometry, options),
            writable: true,
            enumerable: true,
            configurable: true,
          })
        })

        // sort locations based on distance results
        geojson.features.sort(function (a, b) {
          if (a.properties.distance > b.properties.distance) {
            return 1
          }
          if (a.properties.distance < b.properties.distance) {
            return -1
          }
          return 0 // a must be equal to b
        })

        hideReps()

        // assign the new order attributes to the sidebar elements
        geojson.features.forEach(function (company, index) {
          document.getElementById(
            company.properties.target
          ).dataset.order = index
          // get the distance to the nearest SIMPAS dealer
          if (tempSimpas === 0 && company.properties.className.indexOf('simpas') >= 0) {
            tempSimpas = 1
            tempSimpasDist = company.properties.distance
          }
        })

        // show number to call if distance is greater than 50 miles then reset variables for next search
        if (tempSimpasDist > 50) {
          // isolate the user targeted state
          let tarState = ''
          // this line is in case the user only specified a state
          if (e.result.context.length < 2){
            tarState = e.result['text_en-US']
          } else {
            // if the user searched for a zip code or city+state
            tarState = e.result.context.find(element => element.short_code)['text_en-US']
          }

          // show the simpas rep
          let repTar = 'simpas-reps-' + reps.find(element => element.states.find(state => state === tarState)).id
          document.getElementById(repTar).classList.toggle('simpas-reps-hide')
          document.getElementById(repTar).classList.toggle('simpas-reps-show')
        }
        tempSimpas = 0
        tempSimpasDist = 0

        // reorder the list based on distance with closest first and furthest last
        markerSwap(geojson.features[0].properties.target, false)

        // calculate the zoom so that the user can view both the nearest location and the entered location
        var lats = [
          geojson.features[0].geometry.coordinates[1],
          e.result.geometry.coordinates[1],
        ]
        var lons = [
          geojson.features[0].geometry.coordinates[0],
          e.result.geometry.coordinates[0],
        ]
        var sortedLons = lons.sort(function (a, b) {
          if (a > b) {
            return 1
          }
          if (a.distance < b.distance) {
            return -1
          }
          return 0
        })
        var sortedLats = lats.sort(function (a, b) {
          if (a > b) {
            return 1
          }
          if (a.distance < b.distance) {
            return -1
          }
          return 0
        })
        var bbox = [
          [sortedLons[0], sortedLats[0]],
          [sortedLons[1], sortedLats[1]],
        ]

        // apply calculated zoom to the mapbox with some offset padding as needed
        mapbox.fitBounds(bbox, {
          maxZoom: 13,
          padding: { top: 50, bottom: 50, left: offset() * 2 + 50, right: 50 },
        })

        // clear the input field for next search
        document.getElementsByClassName(
          "mapboxgl-ctrl-geocoder--input"
        )[0].value = ""
      })
      markerSwap("map-5a645361-e854-5ec2-85f4-376a4ef7d6f0", false)
    })
  }

  render() {
    // set all those variables based on contentful query
    const mapIconImg = get(this, "props.data.mapIconImg")
    const markerSimpasIdleImg = get(this, "props.data.markerSimpasIdleImg")
    const markerSimpasIdleShadowlessImg = get(
      this,
      "props.data.markerSimpasIdleShadowlessImg"
    )
    const markerSimpasSelectedImg = get(
      this,
      "props.data.markerSimpasSelectedImg"
    )
    const markerTrimbleIdleImg = get(this, "props.data.markerTrimbleIdleImg")
    const markerTrimbleIdleShadowlessImg = get(
      this,
      "props.data.markerTrimbleIdleShadowlessImg"
    )
    const markerTrimbleSelectedImg = get(
      this,
      "props.data.markerTrimbleSelectedImg"
    )
    const companies = get(this, "props.data.allContentfulProvider")
    gCompanies = companies.edges
    // Filter out any SaS Retail Agents here for now
    // Note: We should remove these items from Contentful if they won't be needed in the future
    gCompanies = gCompanies.filter(company => company.node.simpasOrTrimble !== "SIMPAS")

    // set base map styles
    const mapContainerStyle = {
      width: "100%",
      height: "500px",
    }

    // create the CSS variables for the 6 types of markers from contentful images
    const markerStyle =
      "{--markerSI: url(" +
      markerSimpasIdleImg.file.url +
      "); --markerSS: url(" +
      markerSimpasSelectedImg.file.url +
      "); --markerSIS: url(" +
      markerSimpasIdleShadowlessImg.file.url +
      "); --markerTI: url(" +
      markerTrimbleIdleImg.file.url +
      "); --markerTS: url(" +
      markerTrimbleSelectedImg.file.url +
      "); --markerTIS: url(" +
      markerTrimbleIdleShadowlessImg.file.url +
      ")}"

    // function for the view/show map button on mobile
    function mapMobile() {
      // most of this is done via CSS with the class map-hide
      document.getElementById("map-container").classList.toggle("map-hide")

      // change aria label and text of the toggle button
      document.getElementById("map-toggle").ariaLabel === "Show Map"
        ? (document.getElementById("map-toggle").ariaLabel = "Hide Map")
        : (document.getElementById("map-toggle").ariaLabel = "Show Map")
      document.querySelectorAll("#map-toggle span")[0].innerHTML === "Show Map"
        ? (document.querySelectorAll("#map-toggle span")[0].innerHTML =
            "Hide Map")
        : (document.querySelectorAll("#map-toggle span")[0].innerHTML =
            "Show Map")

      // this line fixes the infrequent issue concerning the map not resizing to full width on mobile on first load
      window.dispatchEvent(new Event("resize"))

    }

    return (
      <Layout bodyClass="find-a-provider">
        <Seo title="Find a Provider" description="Locate participating SIMPAS and SIMPAS-Applied Solution (SaS) dealers near you." />
        <section className="hero--faq padding hero">
          <div>
            <h1>Find a Dealer</h1>
          </div>
           {/* How to Get Started with SIMPAS */}
        <section id="simpas-get-started">
          <div className="container flow--thick">
            <div className="get-started bg--white bg--shadow">
              <div className="text-center">
                <h2>How Do I Get Started with SIMPAS<sup>&reg;</sup>?</h2>
                <p>
                  Change the way you treat pressure areas in three simple steps.
                </p>

                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="gs">
                      <p className="gs__number gs__mobile">1</p>
                      <StaticImage
                        src="../images/icon-1.png"
                        alt=""
                        className="gs__img"
                      />
                      <div className="gs__flex">
                        <p className="gs__number gs__desktop">1</p>
                        <p className="gs__text">
                          Contact your crop inputs retailer to review the SIMPAS-applied Solutions&trade;&nbsp;portfolio.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="gs">
                      <p className="gs__number gs__mobile">2</p>
                      <StaticImage
                        src="../images/icon-2.png"
                        alt=""
                        className="gs__img"
                      />
                      <div className="gs__flex">
                        <p className="gs__number gs__desktop">2</p>
                        <p className="gs__text">
                          Work with your trusted advisor to develop
                          prescriptions that address targeted agronomic&nbsp;needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="gs">
                      <p className="gs__number gs__mobile">3</p>
                      <StaticImage
                        src="../images/icon-3.png"
                        alt=""
                        className="gs__img"
                      />
                      <div className="gs__flex">
                        <p className="gs__number gs__desktop">3</p>
                        <p className="gs__text">
                          Purchase SIMPAS<sup>&reg;</sup> equipment from a participating Trimble<sup>&reg;</sup>&nbsp;dealer.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END - Get Started with SIMPAS */}
          <div id="simpas-reps-CR" className="simpas-reps simpas-reps-hide">
            <a id="close-reps" onClick={hideReps}>x</a>
            <h2>Please contact us directly to find a SIMPAS-applied Solutions&trade; Retail Agent near you.</h2>
            <div>
              <p>
                <strong className="simpas-reps__name">Cory Ritter</strong>
                <br />
                SIMPAS Technologies
                <br />
                Technical Representative
                <br />
                <a href="mailto:CoryR@AMVAC.com" target="_blank" rel="noopener noreferrer">CoryR@AMVAC.com</a>
                <a href="tel:+12178231406" className="tel-num">217-823-1406</a>
              </p>
            </div>
          </div>
          <div id="simpas-reps-KO" className="simpas-reps simpas-reps-hide">
            <a id="close-reps" onClick={hideReps}>x</a>
            <h2>Please contact us directly to find a SIMPAS-applied Solutions&trade; Retail Agent near you.</h2>
            <div>
              <p>
                <strong className="simpas-reps__name">Kyle Ostenson</strong>
                <br />
                SIMPAS Technologies
                <br />
                Technical Representative
                <br />
                <a href="mailto:KyleO@AMVAC.com" target="_blank" rel="noopener noreferrer">KyleO@AMVAC.com</a>
                <a href="tel:+17016304728" className="tel-num">
                701-630-4728
                </a>
              </p>
            </div>
          </div>
          <div id="simpas-reps-DE" className="simpas-reps simpas-reps-hide">
            <a id="close-reps" onClick={hideReps}>x</a>
            <h2>Please contact us directly to find a SIMPAS-applied Solutions&trade; Retail Agent near you.</h2>
            <div>
              <p>
                <strong className="simpas-reps__name">Dusty Engel</strong>
                <br />
                SIMPAS Technologies
                <br />
                Technical Representative
                <br />
                <a href="mailto:DustyE@AMVAC.com" target="_blank" rel="noopener noreferrer">DustyE@AMVAC.com</a>
                <a href="tel:+17065641066" className="tel-num">
                706-564-1066
                </a>
              </p>
            </div>
          </div>
          <div id="simpas-reps-BM" className="simpas-reps simpas-reps-hide">
            <a id="close-reps" onClick={hideReps}>x</a>
            <h2>Please contact us directly to find a SIMPAS-applied Solutions&trade; Retail Agent near you.</h2>
            <div>
              <p>
                <strong className="simpas-reps__name">Brandon Messer</strong>
                <br />
                SIMPAS Technologies
                <br />
                Technical Representative
                <br />
                <a href="mailto:BrandonM@AMVAC.com" target="_blank" rel="noopener noreferrer">BrandonM@AMVAC.com</a>
                <a href="tel:+18152753742" className="tel-num">
                815-275-3742
                </a>
              </p>
            </div>
          </div>
        </section>
        <section id="map-container" className="map-hide">
          <div className="map">
            <div
              ref={el => (this.mapContainer = el)}
              className="mapContainer"
              style={mapContainerStyle}
            />
            <div className="map-bar out">
              <button
                id="map-bar-close"
                onClick={mapOut}
                aria-label="Minimize Locations Sidebar"
                type="button"
              >
                <span>&#9664;</span>
              </button>
              <form>
                <fieldset>
                  <div id="geocoder"></div>
                </fieldset>
                <div className="resultsContainer">
                  <span id="results" className="map-results">
                    Enter your location to find providers near&nbsp;you.
                  </span>
                  <button
                    id="map-toggle"
                    className="btn btn--blue"
                    onClick={mapMobile}
                    aria-label="Show Map"
                    type="button"
                  >
                    <Img alt="" fluid={mapIconImg.fluid} />
                    <span>Show Map</span>
                  </button>
                </div>
              </form>
              <div className="map-overflow">
                <div id="map-locations">
                  <style>html {markerStyle}</style>
                  <MapCompany companies={gCompanies} />
                </div>
              </div>
            </div>
          </div>
        </section>

       
        {/* Button Block */}
        <section className="bg--blue contact">
          <div className="text-center">
            <h2>More Questions?</h2>
            <p>Learn how SIMPAS-applied Solutions&trade; Can Save You Money and Increase&nbsp;Yield.</p>
            <div className="container">
              <div>
                <div className="text-center">
                  <a className="btn" href="/contact-us">
                    Connect with a Specialist
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Button Block */}
      </Layout>
    )
  }
}
export default LocationsPage

export const LocationsPageQuery = graphql`
  query LocationsPageQuery {
    searchIconImg: contentfulAsset(title: { eq: "search-icon" }) {
      id
      description
      title
      fluid(maxWidth: 34, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
    }
    mapIconImg: contentfulAsset(title: { eq: "map-icon" }) {
      id
      description
      title
      fluid(maxWidth: 9, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
    }
    markerSimpasIdleImg: contentfulAsset(title: { eq: "marker-simpas-idle" }) {
      id
      description
      title
      fluid(maxWidth: 68, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
      file {
        url
      }
    }
    markerSimpasIdleShadowlessImg: contentfulAsset(
      title: { eq: "marker-simpas-idle-shadowless" }
    ) {
      id
      description
      title
      fluid(maxWidth: 68, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
      file {
        url
      }
    }
    markerSimpasSelectedImg: contentfulAsset(
      title: { eq: "marker-simpas-selected" }
    ) {
      id
      description
      title
      fluid(maxWidth: 68, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
      file {
        url
      }
    }

    markerTrimbleIdleImg: contentfulAsset(
      title: { eq: "marker-trimble-idle" }
    ) {
      id
      description
      title
      fluid(maxWidth: 68, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
      file {
        url
      }
    }
    markerTrimbleIdleShadowlessImg: contentfulAsset(
      title: { eq: "marker-trimble-idle-shadowless" }
    ) {
      id
      description
      title
      fluid(maxWidth: 68, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
      file {
        url
      }
    }
    markerTrimbleSelectedImg: contentfulAsset(
      title: { eq: "marker-trimble-selected" }
    ) {
      id
      description
      title
      fluid(maxWidth: 68, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
      file {
        url
      }
    }
    allContentfulProvider {
      edges {
        node {
          id
          businessName
          address
          contactPhoneNumber
          locationCoordinates {
            lat
            lon
          }
          simpasOrTrimble
          websiteUrl
          city
          zipCode
          state
        }
      }
    }
  }
`
